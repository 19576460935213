import { useState, useCallback } from 'react';

import { refetchTokens } from '../utils/refetchTokens';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useHttp = (relativeUrl = '') => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const request = useCallback(async (options = {}) => {
        let token = localStorage.getItem('token');

        if (!token) {
            setError(new Error('Токен отсутствует. Авторизация не выполнена.'));
            return null;
        }

        const defaultOptions = (t) => ({
            headers: {
                'Authorization': `Bearer ${t}`,
                'Content-Type': 'application/json',
            },
        });

        const mergedOptions = { ...defaultOptions(token), ...options, mode: 'cors' };
        setIsLoading(true);

        try {
            let response = await fetch(`${BASE_URL}/${relativeUrl}`, mergedOptions);

            if (response.status === 401) {
                console.warn('Токен просрочен. Выполняется обновление...');

                await refetchTokens();

                token = localStorage.getItem('token');
                if (!token) throw new Error('Токен не был успешно обновлён');

                const newOptions = { ...defaultOptions(token), ...options, mode: 'cors' };
                response = await fetch(`${BASE_URL}/${relativeUrl}`, newOptions);
            }

            if (!response.ok) {
                try {
                    const errorData = await response.json();
                    throw new Error(errorData?.message || `Ошибка запроса: ${response.status}`);
                } catch (jsonError) {
                    throw new Error(`Ошибка запроса: ${response.statusText || response.status}`);
                }
            }

            const responseData = await response.json();
            setData(responseData);
            return responseData;

        } catch (err) {
            console.error('Ошибка запроса:', err.message);
            setError(err);
            setData(null);
            return null;

        } finally {
            setIsLoading(false);
        }
    }, [relativeUrl]);


    return [data, request, error, isLoading, setError, setData];
};

export default useHttp;
