import React, {memo} from 'react';

import {Box, Typography} from '@mui/material';
import styled from '@emotion/styled';

import {ChatHeader} from '../../components/Chat/ChatHeader';
import {ChatBody} from '../../components/Chat/ChatBody';
import {ChatFooter} from '../../components/Chat/ChatFooter';

import {currentLang} from '../../App';
import theme from './../../MUI/theme';
import parse from "html-react-parser";

const ChatStyled = styled(Box)((theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    // backgroundColor: '#fff',
}));

export const Chat = memo(
    ({
         socketInstance,
         messages,
         defaultLang,
         channels,
         members,
         sendMessage,
         copyDisabled,
         widgetId,
         messagesPreload,
         showRequest,
         visitorMessages,
         onClose,
         onShowRequest,
         isInSchedule,
         onClick,
         onCancel,
         isChatActive,
         isOnline,
         dialogId,
         logotype,
         hasPreloadedMessages,
         isDialogActive,
         messagesLoading,
         titleMessages,
         sendMessageWithActiveDialog,
         name = 'site name',
     }) => {
        const OutOFScheduleTitle = titleMessages?.non_work?.greeting[defaultLang];

        return (
            <ChatStyled>
                <ChatHeader
                    isDialogActive={isDialogActive}
                    managersList={members}
                    logotype={logotype}
                    isOnline={isOnline}
                    showCloseButton={!!messages.length}
                    onClose={onClose}
                    onCancel={onCancel}
                    onClick={onClick}
                    name={name}
                />
                {/* {1 ? ( */}
                {isInSchedule ? (
                    <ChatBody
                        socketInstance={socketInstance}
                        widgetId={widgetId}
                        messagesLoading={messagesLoading}
                        messagesPreload={messagesPreload}
                        sendMessageWithActiveDialog={sendMessageWithActiveDialog}
                        sendMessage={sendMessage}
                        titleMessages={titleMessages}
                        dialogId={dialogId}
                        onClick={onShowRequest}
                        showRequest={showRequest}
                        defaultLang={defaultLang}
                        messages={messages}
                        hasPreloadedMessages={hasPreloadedMessages}
                        isDialogActive={isDialogActive}
                        visitorMessages={visitorMessages}
                        isChatActive={isChatActive}
                    />
                ) : (
                    <OutOFSchedule color="primary.main" message={OutOFScheduleTitle}/>
                )}
                <ChatFooter
                    socketInstance={socketInstance}
                    defaultLang={defaultLang}
                    copyDisabled={copyDisabled}
                    channels={channels}
                    sendMessage={sendMessage}
                    sendMessageWithActiveDialog={sendMessageWithActiveDialog}
                    showSocials={!messages.length}
                    isInSchedule={isInSchedule}
                    dialogId={dialogId}
                    isChatActive={!isInSchedule}
                    messages={messages}
                    isDialogActive={isDialogActive}
                    visitorMessages={visitorMessages}
                />
            </ChatStyled>
        );
    }
);

const OutOFSchedule = ({message}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                rowGap: '10px',
                padding: '14px',
            }}
        >
            {/*<Typography*/}
            {/*    sx={(theme) => ({*/}
            {/*        mb: 1.9,*/}
            {/*        fontSize: '14px',*/}
            {/*        fontFamily: theme.typography.fontFamily,*/}
            {/*    })}*/}
            {/*>*/}
            {/*</Typography>*/}
            <Box
                sx={(theme) => ({
                    color: 'header.main',
                    width: '100%',
                    p: {
                        fontFamily: theme.typography.fontFamily,
                    }
                })}
            >
                {/* Добро пожаловать! */}
                {message ? parse(message) : ''}
            </Box>
            {/* <Box>{message}</Box> */}
        </Box>
    );
};
